.login-page {
  width: 480px;
  margin: auto;
  margin-top: 10%;
  .Polaris-Card {
    padding: 48px;
  }

  &__warnings {
    margin-top: 1rem;
  }
}
