.textarea-marginBottom_20 {
  margin-bottom: 20px;
}

.cmt-marginBottom_10 {
  margin-bottom: 10px;
}

.cmt-marginTop_10 {
  margin-top: 10px;
}

.cmt-marginTop_5 {
  margin-top: 5px;
}

.btn-edit-list {
  margin: 5px 8px;
  padding-left: 10px;
  display: flex;
  gap: 24px;
}
