.company-select {
  display: flex;

  button {
    margin-left: 3px;
  }
}

.company_select_box {
  .Polaris-TopBar__RightContent {
    align-items: center;
    gap: 13px;
  }
}

.company_chart {
  background-color: #fff;
  text-align: center;
  display: flex;
  justify-content: center;

  #img-mapper {
    text-align: center;
    img {
      position: inherit !important;
    }
  }
}
