.order-form {
  &__bottom {
    display: flex;
    justify-content: space-between;
  }
}

.order-form-period {
  gap: 1rem;
}
