.viewModal {
  .height {
    height: 99%;
    .Polaris-LegacyCard {
      padding: 10px;
      height: 99%;
    }
  }

  .content {
    margin-top: 15px;
  }

  .marginBottom_10 {
    margin-bottom: 10px;
  }
}
@media (max-width: 768px) {
  .Polaris-Box {
    right: 5px;
    top: 3px;
  }
}
