.Polaris-Sheet__Container {
  z-index: 999;
}

.flex {
  display: flex;
}

.layout-column {
  display: flex;
  flex-direction: column;
}

.layout-row {
  display: flex;
  flex-direction: row;
}

.justify-space {
  justify-content: space-between;
}

.flex-gap {
  &--1rem {
    gap: 1rem;
  }
}

.flex-grow {
  flex-grow: 1;
}

a {
  color: var(--p-color-text);
}

.dateInput_width {
  .Polaris-BlockStack {
    .Polaris-Box {
      width: 100%;
      min-width: 100%;
    }
  }
}
