.loading-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100px; /* Adjust height as needed */
}

.loading-container.small {
  height: 50px; /* Adjust height for small size */
}

.loading-container.big {
  height: 100px; /* Adjust height for big size */
}

.loading-spinner {
  border: 4px solid rgba(0, 0, 0, 0.1);
  border-left-color: #3f51b5; /* Primary color */
  border-radius: 50%;
  animation: spin 1s linear infinite;
}

.loading-container.small .loading-spinner {
  width: 20px; /* Adjust width for small size */
  height: 20px; /* Adjust height for small size */
}

.loading-container.big .loading-spinner {
  width: 40px; /* Adjust width for big size */
  height: 40px; /* Adjust height for big size */
}

@keyframes spin {
  to {
    transform: rotate(360deg);
  }
}
