.Polaris-Modal-Dialog {
  &--sizeLarge {
    max-width: 90% !important;
  }
}

.project-board {
  & .Polaris-Card {
    &:nth-child(2) {
      flex-grow: 1;
      margin: 0;
    }
  }

  &__content {
    width: 100%;
  }
}

.project-board-column {
  background-color: #cecece;
  padding: 10px;
  border-radius: 5px;
  width: 320px;
  flex: 0 0 auto;
  margin-right: 20px;
}
