.main-section {
  padding: 70px 0px;
  min-width: 1260px;
  max-width: 1260px;
  overflow: auto;
  margin: 0 auto;

  .cursor_pointer {
    cursor: pointer;
  }

  .container {
    --bs-gutter-x: 1.5rem;
    --bs-gutter-y: 0;
    width: 100%;
    padding-right: calc(var(--bs-gutter-x) * 0.5);
    padding-left: calc(var(--bs-gutter-x) * 0.5);
    margin-right: auto;
    margin-left: auto;
  }

  .followChart {
    .two-underline {
      position: relative;
      height: 104px;

      &:before {
        content: "";
        width: 88%;
        height: 5px;
        background: #c7c7c7;
        position: absolute;
        top: 50%;
        transform: translate(-50%, -10px);
        left: 50%;
      }
    }

    .cursor_pointer {
      p {
        color: #005bd3 !important;
        text-decoration: underline;
      }
    }

    .common-class {
      img {
        max-width: 160px;
        max-height: 55px;
        margin: 0 auto;
        display: flex;
        justify-content: center;
      }

      p {
        width: 100%;
        display: flex;
        justify-content: center;
        font-size: 12px;
        font-weight: 600;
        color: #393027;
        max-width: 207px;
        text-align: center;
        min-height: 20px;
      }

      .lastText_box {
        min-height: 40px;
        min-width: 207px;
      }
    }

    .one {
      margin: 0px auto 20px;
      position: relative;
      padding-bottom: 80px;
      width: max-content;
      background: #f1f1f1;
      display: flex;
      justify-content: center;
      flex-wrap: wrap;

      &:after {
        content: "";
        height: 60px;
        width: 5px;
        background: #c7c7c7;
        position: absolute;
        left: 50%;
        transform: translate(-50%, 0%);
        bottom: 8px;
      }

      &:before {
        content: "";
        width: 15px;
        height: 15px;
        background: #c7c7c7;
        bottom: 0px;
        position: absolute;
        left: 50%;
        transform: translate(-50%, 0%);
        clip-path: polygon(100% 0, 0 0, 50% 100%);
      }
    }

    .two {
      margin: 0px auto;
      position: relative;
      padding: 0px 18px;
      padding-bottom: 20px;
      width: max-content;
      background: #f1f1f1;
    }

    .three {
      display: flex;
      justify-content: space-between;
      flex-wrap: wrap;
      padding-top: 80px;

      .text {
        position: relative;
        padding-top: 8px;

        &:after {
          content: "";
          height: 140px;
          width: 5px;
          background: #c7c7c7;
          position: absolute;
          right: 72px;
          transform: translate(0%, -100%);
          top: -1px;
        }

        &:before {
          content: "";
          width: 15px;
          height: 15px;
          background: #c7c7c7;
          top: 6px;
          position: absolute;
          right: 60px;
          transform: translate(-50%, -100%);
          clip-path: polygon(100% 0, 0 0, 50% 100%);
        }
      }

      .textt {
        position: relative;
        padding-top: 8px;

        &:after {
          content: "";
          height: 140px;
          width: 5px;
          background: #c7c7c7;
          position: absolute;
          left: 73px;
          transform: translate(0%, -100%);
          top: -1px;
        }

        &:before {
          content: "";
          width: 15px;
          height: 15px;
          background: #c7c7c7;
          top: 6px;
          position: absolute;
          left: 75px;
          transform: translate(-50%, -100%);
          clip-path: polygon(100% 0, 0 0, 50% 100%);
        }
      }
    }

    .for {
      display: flex;
      justify-content: space-between;
      flex-wrap: wrap;
      width: 72%;
      margin: 0px auto;
      position: relative;
      margin-top: -88px;
      padding-top: 20px;

      &:before {
        content: "";
        display: flex;
        position: absolute;
        height: 5px;
        width: 78%;
        background: #c7c7c7;
        left: 50%;
        transform: translate(-50%, -100%);
        top: -56px;
      }

      .text {
        position: relative;
        padding-top: 6px;

        &:after {
          content: "";
          height: 80px;
          width: 5px;
          background: #c7c7c7;
          position: absolute;
          right: 98px;
          transform: translate(0%, -100%);
          top: -1px;
        }

        &:before {
          content: "";
          width: 15px;
          height: 15px;
          background: #c7c7c7;
          top: 6px;
          position: absolute;
          right: 85px;
          transform: translate(-50%, -100%);
          clip-path: polygon(100% 0, 0 0, 50% 100%);
        }
      }

      .textt {
        position: relative;
        padding-top: 6px;

        &:after {
          content: "";
          height: 80px;
          width: 5px;
          background: #c7c7c7;
          position: absolute;
          left: 94px;
          transform: translate(0%, -100%);
          top: -1px;
        }

        &:before {
          content: "";
          width: 15px;
          height: 15px;
          background: #c7c7c7;
          top: 6px;
          position: absolute;
          left: 96px;
          transform: translate(-50%, -100%);
          clip-path: polygon(100% 0, 0 0, 50% 100%);
        }
      }
    }

    .five {
      display: flex;
      justify-content: space-between;
      flex-wrap: wrap;
      width: 38%;
      margin: -82px auto 0px;
      position: relative;

      &:before {
        content: "";
        display: flex;
        position: absolute;
        height: 5px;
        width: 61%;
        background: #c7c7c7;
        left: 50%;
        transform: translate(-50%, -100%);
        top: -56px;
      }

      &:after {
        content: "";
        width: 5px;
        height: 28px;
        background: #c7c7c7;
        position: absolute;
        left: 50%;
        transform: translate(-50%, -100%);
        top: -58px;
      }

      .text {
        position: relative;
        padding-top: 6px;

        &:after {
          content: "";
          height: 60px;
          width: 5px;
          background: #c7c7c7;
          position: absolute;
          right: 92px;
          transform: translate(0%, -100%);
          top: -1px;
        }

        &:before {
          content: "";
          width: 15px;
          height: 15px;
          background: #c7c7c7;
          top: 6px;
          position: absolute;
          right: 80px;
          transform: translate(-50%, -100%);
          clip-path: polygon(100% 0, 0 0, 50% 100%);
        }
      }

      .textt {
        position: relative;
        padding-top: 6px;

        &:after {
          content: "";
          height: 60px;
          width: 5px;
          background: #c7c7c7;
          position: absolute;
          left: 90px;
          transform: translate(0%, -100%);
          top: -1px;
        }

        &:before {
          content: "";
          width: 15px;
          height: 15px;
          background: #c7c7c7;
          top: 6px;
          position: absolute;
          left: 92px;
          transform: translate(-50%, -100%);
          clip-path: polygon(100% 0, 0 0, 50% 100%);
        }
      }
    }

    .six {
      display: flex;
      justify-content: space-between;
      flex-wrap: wrap;
      width: min-content;
      position: relative;
      padding-top: 86px;
      padding-left: 152px;

      &:before {
        content: "";
        position: absolute;
        height: 50px;
        width: 5px;
        background: #c7c7c7;
        left: 80px;
        top: -10px;
      }

      &:after {
        content: "";
        position: absolute;
        height: 5px;
        width: 140px;
        background: #c7c7c7;
        left: 80px;
        top: 35px;
      }

      .text {
        position: relative;
        padding-top: 18px;

        &:after {
          content: "";
          height: 50px;
          width: 5px;
          background: #c7c7c7;
          position: absolute;
          right: 92px;
          transform: translate(0%, -100%);
          top: -1px;
        }

        &:before {
          content: "";
          width: 15px;
          height: 15px;
          background: #c7c7c7;
          top: 6px;
          position: absolute;
          right: 80px;
          transform: translate(-50%, -100%);
          clip-path: polygon(100% 0, 0 0, 50% 100%);
        }
      }
    }

    .doubleBox {
      display: flex;
      justify-content: space-between;
      align-items: center;
    }

    .sevan {
      position: relative;
      padding-top: 87px;

      &:before {
        // content: "";
        // height: 5px;
        // width: 240px;
        // background: #c7c7c7;
        // position: absolute;
        // right: 92px;
        // transform: translate(0%, -100%);
        // top: 50px;
        content: "";
        height: 5px;
        width: 270px;
        background: #c7c7c7;
        position: absolute;
        right: 92px;
        transform: translate(0%, -100%);
        top: 60px;
      }

      .mix-box {
        display: flex;
        align-items: center;
        gap: 60px;

        .extra-line {
          position: relative;

          &:before {
            content: "";
            height: 5px;
            width: 338px;
            background: #c7c7c7;
            position: absolute;
            right: -73px;
            transform: translate(50%, -100%);
            top: -81px;
          }

          &:after {
            content: "";
            height: 33px;
            width: 5px;
            background: #c7c7c7;
            position: absolute;
            right: -237px;
            transform: translate(100%, -100%);
            top: -81px;
          }
        }

        .text {
          position: relative;
          padding-top: 45px;

          &:after {
            content: "";
            height: 61px;
            width: 5px;
            background: #c7c7c7;
            position: absolute;
            right: 92px;
            transform: translate(0%, -100%);
            top: 18px;
          }

          &:before {
            content: "";
            width: 15px;
            height: 15px;
            background: #c7c7c7;
            top: 24px;
            position: absolute;
            right: 80px;
            transform: translate(-50%, -100%);
            clip-path: polygon(100% 0, 0 0, 50% 100%);
          }
        }

        .textt {
          position: relative;
          padding-top: 27px;

          &:after {
            // content: "";
            // height: 114px;
            // width: 5px;
            // background: #c7c7c7;
            // position: absolute;
            // right: 92px;
            // transform: translate(0%, -100%);
            // top: -1px;

            content: "";
            height: 92px;
            width: 5px;
            background: #c7c7c7;
            position: absolute;
            right: 92px;
            transform: translate(0%, -100%);
            top: 18px;
          }

          &:before {
            content: "";
            width: 15px;
            height: 15px;
            background: #c7c7c7;
            top: 26px;
            position: absolute;
            right: 80px;
            transform: translate(-50%, -100%);
            clip-path: polygon(100% 0, 0 0, 50% 100%);
          }
        }

        .texttt {
          position: relative;
          padding-top: 27px;

          &:after {
            content: "";
            height: 84px;
            width: 5px;
            background: #c7c7c7;
            position: absolute;
            right: 92px;
            transform: translate(0%, -100%);
            top: -1px;
          }

          &:before {
            content: "";
            width: 15px;
            height: 15px;
            background: #c7c7c7;
            top: 6px;
            position: absolute;
            right: 80px;
            transform: translate(-50%, -100%);
            clip-path: polygon(100% 0, 0 0, 50% 100%);
          }
        }
      }
    }
  }
}

@media (min-width: 700px) {
  .container,
  .container-lg,
  .container-md,
  .container-sm,
  .container-xl {
    max-width: 1260px !important;
  }
}

// @media (min-width: 992px) {

//   .container,
//   .container-lg,
//   .container-md,
//   .container-sm,
//   .container-xl {
//     max-width: 1040px;
//   }
// }

// @media (min-width: 768px) {

//   .container,
//   .container-lg,
//   .container-md,
//   .container-sm {
//     max-width: 720px;
//   }
// }

// @media (min-width: 576px) {

//   .container,
//   .container-sm {
//     max-width: 1110px;
//   }
// }

@media (max-width: 1200px) {
  .main-section .followChart .two-underline:before {
    content: "";
    // width: 87%;
    height: 5px;
    background: #c7c7c7;
    position: absolute;
    top: 50%;
    transform: translate(-50%, -10px);
    left: 50%;
  }

  .main-section .followChart .for:before {
    content: "";
    display: flex;
    position: absolute;
    height: 5px;
    // width: 75%;
    background: #c7c7c7;
    left: 50%;
    transform: translate(-50%, -100%);
    top: -56px;
  }

  .main-section .followChart .five:before {
    content: "";
    display: flex;
    position: absolute;
    height: 5px;
    // width: 56%;
    background: #c7c7c7;
    left: 50%;
    transform: translate(-50%, -100%);
    top: -56px;
  }
}
