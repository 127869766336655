.customer-form {
  padding-bottom: 2rem;
}

.layout-view {
  position: fixed;
  height: 100%;
  width: 100%;
  background-color: #ffffff;
  z-index: 600;
  left: 0;
  top: 0;
}
