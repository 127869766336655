.content_gap {
  gap: 18px;
}

.content_break {
  word-break: break-all;
}

.content_margin {
  margin: 3px 0px;
}

.buttons_gap {
  gap: 3px;
}
