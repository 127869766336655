.company_select_box {
  .Polaris-TopBar__LeftContent {
    a {
      display: none;
    }
  }
}
.logo_text {
  font-size: 20px;
  color: #ffffff;
}

.Polaris-Icon {
  margin: 0;
}

.main-header {
  background-color: #000;
  padding: 0;
  position: sticky;
  top: 0;
  z-index: 99;
  transition: background-color 0.3s, box-shadow 0.3s;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
}

.main-header__menu {
  display: flex;
  list-style: none;
  padding: 0;
  margin: 0;
  align-items: center;
}

.main-header__item {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.main-header__company-select {
  margin-right: auto;
}

.main-header__menu-item {
  margin-right: 20px;
}

.main-header__link {
  text-align: center;
  color: #fff;
  font-size: 14px;
  padding: 7px 8px;
  border-radius: 6px;
  cursor: pointer;
  height: 79px;
  display: inline-flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  transition: background-color 0.3s, transform 0.2s;
}

.nav-active .main-header__link {
  box-shadow: inset 0px 0px 6px 6px rgba(215, 215, 215, 0.28);
}

.main-header__logo-suffix {
  color: #fff;
  font-weight: bold;
  font-size: 16px;
  margin: auto;
}

.main-header__user-menu {
  margin-left: auto;
}

.sub-header {
  background-color: #fff;
  padding: 10px;
  box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.1);
  position: sticky;
  top: 80px;
  z-index: 99;
  transition: background-color 0.3s, box-shadow 0.3s;
}

.sub-header__menu {
  display: flex;
  list-style: none;
  padding: 0;
  margin: 0;
  align-items: center;
  justify-content: space-between;
}

.sub-header__settings {
  display: flex;
  flex-wrap: wrap;
}

.sub-header__menu-item {
  margin-right: 15px;
  margin-bottom: 5px;
}

.sub-header__link a {
  color: #000;
  font-size: 14px;
  font-weight: bold;
  padding: 8px 12px;
  text-decoration: none;
  border-radius: 4px;
  display: inline-block;
  transition: background-color 0.2s, transform 0.2s;
}
.sub-header__link a:hover {
  background-color: #f0f0f0;
  transform: scale(1.05);
}
