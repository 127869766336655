.left_align {
  .Polaris-BlockStack {
    align-items: flex-start;
  }
}

.column-2 {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  gap: 6px;
}

.marginTop {
  margin-top: 10px;
}
