.arborist-container {
  width: 100% !important;
  /* height: auto; */
  height: calc(100vh - 80px);
}

.arborist-container div {
  width: 100% !important;
  /* max-width: 500px; */
  /* width: 500px !important; */
  /* height: auto !important; */
  /* overflow: hidden !important; */
}



.node {
  display: flex;
  align-items: center;
  padding: 5px 10px;
  border-radius: 4px;
  margin-bottom: 4px;
  transition: background-color 0.2s ease;
}

.node:hover {
  background-color: #e0e0e0;
}

.toggle-button {
  margin-right: 10px;
  border: none;
  background-color: transparent;
  cursor: pointer;
  font-size: 16px;
  font-weight: bold;
  color: #333;
}

.node-content {
  flex-grow: 1;
  font-size: 14px;
  color: #555;
}

.node_txt {
  cursor: pointer;
}

.expanded .node-content {
  font-weight: bold;
  color: #007bff;
}

.active-node span {
  font-weight: bold;
  /* Bold font for the active node */
}

.active-node {
  background-color: transparent;
  /* No background color change */
}

.disabled_btn {
  padding: 12px 12px;
  border: 1px solid #767676;
  opacity: 0;
}

.spinner_cus_main {
  display: flex;
  align-items: center;
  gap: 13px;
  margin-bottom: 18px;
}

.spinner_cus .loading-container.small {
  height: auto !important;
}

.spinner_cus .loading-container.small .loading-spinner {
  width: 20px !important;
}