.Polaris-Modal-Dialog {
  &--sizeLarge {
    max-width: 90% !important;
  }
}

.customer-view {
  & .Polaris-Card {
    &:nth-child(2) {
      flex-grow: 1;
      margin: 0;
    }
  }

  &__content {
    width: 100%;
  }
}
